h1, h2{
    font-family: "Poppins", sans-serif;
    font-weight: 100;
    font-style: normal;
}

  p, h3{
    font-family: "Roboto Condensed", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
  }

