body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  scroll-behavior: smooth;
  width: 100vw;
  margin: 0px;
  padding: 0px;
  margin-left: 0px;
}

h1, h2, h3, h4 {
  color: black;
}

.parallax-section {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  will-change: transform; 
  width: 100vw;
  margin: 0px;
  padding: 0px;
}

.main-content {
  background-image: url('../img/background/Dekstop-01.svg'); 
  background-size: cover;
  background-position: center;
  background-attachment: fixed; 
  justify-content: center;
  align-items: center;
  will-change: transform;
}

.main-content > div {
  width: 100%;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1); 
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .parallax-section {
    height: 100vh; 
    justify-content: center;
    align-items: center;
    width: 100vw;
    margin: 0px;
    padding: 0px;
  }

  .main-content {
    background-size: contain; 
    background-attachment: scroll;
    width: 100vw;
    margin: 0px;
    padding: 0px;
  }

  .main-content > div {
    padding: 20px;
    margin: 20px 10px; 
    justify-content: center;
    align-items: center;
    padding-left: 0px;
  }
}

.contact-section {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1); 
}
@media (max-width: 768px) {
  .contact-section {
    padding: 20px;
    box-shadow: none;
    width: 100vw;
    margin: 0px;
    padding: 0px;
  }

  .main-content {
    background-image: url('../img/background/Mobile.svg'); 
    background-size: cover;
    background-attachment: scroll; 
    background-position: center;
  }
}
