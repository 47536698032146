.ips-container-fluid {
    background-color: rgba(151,207,252,255) ;
    width: 99vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
}

img {
    cursor: default;
}

.armaso-ips {
    cursor: pointer;
}

.bupan {
    cursor: pointer;
}

.daftar {
    cursor: pointer;
}

.pretelan-hp {
    display: none;
}

/* Ombak bawah */
.container-ombak {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 40;
}

.ombak {
    width: 100%;
    height: auto;
    object-fit: cover;
    display: block;
}

/* Pretelan pojok kanan bawah (card, karang, dll) */
.container-alas {
    position: absolute;
    bottom: 8vh;
    right: 0;
    width: 60vw;
    object-fit: contain;
}

.alas1 {
    width: 100%;
    height: auto;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 20;
    height: 35vh;
}

.alas2 {
    width: 100%;
    height: auto;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 10;
    height: 40vh;
}

.alas3 {
    width: 52vw;
    height: auto;
    position: absolute;
    bottom: 0;
    right: 0;
    height: 47vh;
}

.karang {
    width: 50vw;
    height: auto;
    position: absolute;
    bottom: -1.5vh;
    right: 0vw;
    z-index: 30;
    object-fit: contain;
}

.card-ips {
    width: 34vw;
    height: auto;
    position: absolute;
    bottom: 23vh;
    right: 6vw;
    z-index: 5;
    object-fit: contain;
}

/* Circle text */
.circle-text {
    object-fit: contain;
}

.armaso-ips {
    position: absolute;
    top: 7vh;
    left: 4vw;
    width: 30vw;
    height: auto;
    animation-name: armaso;
    animation: shake 0.5s;
    animation-iteration-count: infinite;
}

@keyframes armaso {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
}

.text-social {
    position: absolute;
    top: 20vh;
    left: 3.5vw;
    width: 40vw;
    height: auto;
}

.text-keterangan-ips {
    position: absolute;
    top: 41vh;
    left: 4.5vw;
    width: 35vw;
    height: auto;
}

/* Tombol bagian bawah */
.tombol {
    margin-left: 10.5vw;
    display: flex;
    gap: 2vw;
    transform: translateY(10.5vh);
}

.bupan {
    width: 10%;
    height: auto;
    z-index: 100;
}

.daftar {
    width: 10%;
    height: auto;
    z-index: 100;
}

@media (max-width: 960px) {
    .pretelan-desktop {
        display: none;
    }

    .pretelan-hp {
        display: block;
        z-index: auto;
    }

    .bawah-hp, .bawah-hp2 {
        width: 100%;
        height: auto;
        position: absolute;
        top:  350px;
        display: block;
        object-fit: contain;
        bottom: 0px;
        object-fit: cover;
        left: 0%;
    }

    .bawah-hp2{
        z-index: 1000;
    }

    .karang-hp{
        width: 100%;
        height: auto;
        position: absolute;;
        top: 100px;
        display: block;
        object-fit: contain;
        bottom: 0px;
        object-fit: cover;
        left: 0%;
        z-index: 90 !important;
    }

    .card-ips-hp {
        width: 95%;
        height: auto;
        position: absolute;
        top: 145px;
        display: block;
        object-fit: contain;
        bottom: -1.5vh;
        object-fit: cover;
        left: 1%;
    }

    .top-hp {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 2vh;
    }

    .armaso-ips-hp {
        max-width: 110vw;
        height: auto;
        top: -2vh;
        position: absolute;
        bottom: 0vh;
    }

    .social-hp {
        max-width: 100vw;
        height: auto;
        top: 2vh;
        position: absolute;
    }

    .text-social-hp {
        max-width: 70vw ;
        width: 100vw ;
        height: auto;
        top: 27vh;
        position: absolute;
        left: 15vw;
    }

    .tombol-hp {
        display: flex;
        position: absolute;
        z-index: 1000;
        top: 360px;
        gap: 8vw;
        transform: translateY(-5vh);
        -webkit-transform: translateY(-5vh);
        -moz-transform: translateY(-5vh);
        -ms-transform: translateY(-5vh);
        -o-transform: translateY(-5vh);
}

    .daftar-ips-hp {
        max-width: 30vw;
        height: auto;
        cursor: pointer;
    }

    .bupan-ips-hp {
        max-width: 30vw;
        height: auto;
        cursor: pointer;
    }

}