html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

.hidden {
    opacity: 0;
    transition: opacity 0.5s ease-out;
}

.prhome-container:not(.hidden) {
    opacity: 1;
    transition: opacity 0.5s ease-in;
}
 

.prhome-container {
    will-change: scroll-position;
    height: 100vh;
    width: 100vw;
    background-color: rgb(133, 232, 255);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    display: flex;
    overflow: hidden;
    scroll-behavior: smooth;
}

.border{
    width: 100vw;
    position: absolute;
    display: flex;
    z-index: 21;
}

.border-img{
    width: 100%;
    position: absolute;
}

.grd1{
    position: absolute;
    z-index: 19;
    width: 100%;
    height: 100vh;
    top: -8vh;
}

.grd-img1{
    height: 100vh;
    width: 100%;
}

.grd2{
    position: absolute;
    z-index: 18;
    width: 100%;
    height: 100vh;
    top: 50vh;
    top: -14vh;
}

.grd-img2{
    height: 100vh;
    width: 100%;
}

.grd3{
    position: absolute;
    z-index: 17;
    width: 100%;
    height: 100vh;
    top: -14vh;
}

.grd-img3{
    height: 100vh;
    width: 100%;
}

.armaso{
    position: absolute;
    align-items: center;
    z-index: 16 !important;
    width: 100vw;
    height: 100vh;
    top: 0px;
}

.armaso-img {
    top: -0px;
    align-items: center;
    height: 100vh;
    width: 100vw;
}

.iwak{
    position: absolute;
    z-index: 15;
    width: 100%;
    height: 100vh;
    top: -8vh;
}

.iwak-img{
    height: 100vh;
    width: 100%;
}

.kastil{
    position: absolute;
    z-index: 14;
    width: 100%;
    height: 100vh;
    top: -7vh;
    transform: translateY(0);
}

.kastil-img{
    height: 100vh;
    width: 100%;
}

.grd1, .grd2, .grd3, .kastil {
    transform: translateY(0); 
}


@media only screen and (max-width: 768px) {
    .armaso, .border, .grd1, .grd2, .grd3, .iwak, .kastil{
        display: none;
    }
    
    .borderbawah{
        width: 100vw;
        position: absolute;

        top: 270px;
    }

    .borderbawah-img{
        width: 100%;
        position: absolute;
        z-index: 20;
    }

    .bg1{
        position: absolute;
        z-index: 18;
        width: 100%;
        height: 100vh;
        top: 0px;
    }

    .bg1-img{
        height: 100vh;
        width: 100%;
    }

    .bg2{
        position: absolute;
        z-index: 17;
        width: 100%;
        height: 100vh;
        top: 145px;
    }

    .bg2-img{
        height: auto;
        width: 100%;
    }

    .bg3{
        position: absolute;
        z-index: 14;
        width: 100%;
        height: 100vh;
    }

    .bg3-img{
        height: 100vh;
        width: 100%;
    }

    .watu{
        position: absolute;
        z-index: 16;
        width: 100%;
        height: 100vh;
    }

    .watu-img{
        height: 100vh;
        width: 100%;
    }

    .omah{
        position: absolute;
        z-index: 13;
        width: 100%;
        height: 100vh;
    }

    .omah-img{
        height: 100vh;
        width: 100%;
    }

    .atas{
        position: absolute;
        top: -0px;
        z-index: 13;
        width: 100%;
        height: 100vh;
    }

    .atas-img{
        height: 100vh;
        width: 100%;
    }

    .jeneng{
        position: absolute;
        z-index: 15;
        width: 100%;
        height: 100vh;
        top: -100px;
    }

    .jeneng-img{
        height: 100vh;
        width: 100%;
    }
}