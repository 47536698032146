.tl-container {
  width: 100%;
}


.buttons{
  padding: 20px;
}

.judul-tl, .judul-title{
  color: #000;
  text-align: center;
}

.judul-tl{
  font-size: 40px;
}

.button-container {
  display: flex;
  justify-content: center;
  background-color: rgb(179, 214, 214);
  padding: 5px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  max-width: 600px; 
  margin: 0 auto;
}

button {
  margin: 5px;
  padding: 0px 0px;
  background-color: rgb(57, 179, 198);
  color: #000;
  border: none;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  position: relative;
  transition: background-color 0.5s ease, color 0.4s ease;
  -webkit-transition: background-color 0.5s ease, color 0.4s ease;
  -moz-transition: background-color 0.5s ease, color 0.4s ease;
  -ms-transition: background-color 0.5s ease, color 0.4s ease;
  -o-transition: background-color 0.5s ease, color 0.4s ease;
}

button:hover::before,
button.active::before {
  width: 50px;
}

button.active {
  color: #fff;
  background-color: rgb(57, 179, 198); 
  padding: 10px 30px;
  border-radius: 20px;
  transition: background-color 0.4s ease, color 0.4s ease;
}

button:not(.active) {
  color: #000;
  background-color: transparent;
}

.box-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 20px;
  width: 100%;
  overflow: hidden;
  flex-wrap: wrap; 
}

.box {
  flex: 0 0 400px;
  border: 2px solid #000000;
  margin: 0 10px;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  opacity: 0;
  height: 150px;
  width: 390px;
  transform: scaleX(0);
  transform-origin: left;
  transition: opacity 0.8s ease-in-out, transform 0.8s ease-in-out;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.box:nth-child(1),
.box:nth-child(2),
.box:nth-child(3) {
  opacity: 1;
  transform: scaleX(1);
}

.box:nth-child(2) {
  transition-delay: 0.2s;
}

.box:nth-child(3) {
  transition-delay: 0.4s;
}

@media (max-width: 480px) {
  .box-container {
    flex-direction: column; 
    align-items: center;    
  }

  .box {
    flex: 0 0 100%;         
    margin: 10px 0;        
  }

  button {
    padding: 5px 15px;      
    font-size: 15px;        
    margin: 5px;            
  }

  p{
    font-size: 20px;
  }
}


