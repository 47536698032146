footer {
    background-color: rgb(0, 221, 255);
    padding: 10px 0;
    text-align: center;
    border-top: 2px solid #ddd;
    margin-bottom: 0;
}

.CR-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0;
    font-size: 16px;
    color: #333;
    padding-bottom: 0;
}

.CR-container p {
    margin: 0;
    padding: 0;
    font-weight: 500;
    color: #000000;
}

.CR-container a, .CR-container span{
    color: #000000;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease;
}

.CR-container a:hover {
    color: #ffffff;
}

@media (max-width: 768px) {
    .CR-container {
        padding: 10px;
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .CR-container {
        padding: 5px;
        font-size: 12px;
    }

    .CR-container a:hover {
        color: #f1f1f1;
    }

}
