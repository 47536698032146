
html {
  scroll-behavior: smooth;
}


p {
  font-family: "Roboto Condensed", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}