.faq-container-fluid {
  background-color: rgba(23, 129, 145, 0.7); 
  width: 100%; 
  margin: 0; 
  padding: 50px; 
}

.judul-faq {
  color: black;
  font-size: 40px;
}

.faq-item {
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
}

.faq-question {
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}

.pertanyaan, .jawaban {
  font-size: 20px;
  color: black;
}

.arrow {
  font-size: 16px;
  transition: transform 0.3s ease;
}

.arrow.open {
  transform: rotate(180deg);
}

.faq-answer {
  max-height: 0;
  overflow: hidden;
  font-size: 16px;
  color: #555;
  transition: max-height 0.5s ease, padding 0.5s ease;
  padding: 0; 
}

.faq-answer.show {
  max-height: 500px; 
  padding: 10px 0; 
}

@media (max-width: 768px) {
  .faq-container-fluid {
    width: 100vw;
    margin: 0;
    padding: 0;
    background-color: rgba(23, 129, 145, 0.7); 
    box-sizing: border-box;
    overflow-x: hidden;
    border-radius: 20px;
  }

  .faq-question {
    font-size: 20px;
    flex-direction: column;
    align-items: flex-start;
  }

  .arrow {
    align-self: flex-end;
    margin-top: 5px;
  }

  .faq-answer {
    font-size: 20px; 
    max-height: 0; 
    padding: 0; 
  }

  .faq-answer.show {
    max-height: 500px; 
    padding: 10px 0; 
  }

  .pertanyaan, .jawaban {
    font-size: 20;
  }
}