.logoarmaso{
  width: 45px;
  height: 45px;
}

.navbar {
  background-color:rgba(6, 138, 158, 0.2) ;
  padding: 10px 0 ;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1) ;
  text-align: center ;
  position: sticky;
  z-index: 100;
}

.nav-container{
  background-color: rgb(0, 208, 255);
  position: sticky;
  z-index: 100;
}

.navbar .navbar-brand {
  margin-right: 15px;
}

.navbar-nav {
  margin: 0 auto; 
}

.navbar-nav .nav-link {
  color: #343a40; 
  font-size:20px;
  transition: color 0.3s ease-in-out;
  text-align: center; 
}

.navbar-nav .nav-link:hover {
  color: #ffffff; 
}

.nav-link{
    text-decoration: double;
}

.button {
  display: flex;
  gap: 10px;
  justify-content: center; 
}

.button .btn {
  font-weight: 500;
  font-size: 14px;
  padding: 8px 20px;
  border-radius: 20px;
  transition: background-color 0.3s ease-in-out;
}

.button .btn-secondary {
  background-color: #6c757d; 
  color: white;
  border: none;
}

.button .btn-secondary:hover {
  background-color: #5a6268; 

@media (max-width: 992px) {
  .navbar .navbar-brand {
    margin-left: 20px;
  }

  .button {
    margin-top: 15px;
    justify-content: center;
  }
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
  text-align: center; 
}

}